import {
  ref,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
  nextTick,
} from "vue";

export function useWindowSizeComputed(threshold) {
  const isLarger = ref(true);
  const windowWidth = ref(0);

  const handleResize = () => {
    windowWidth.value = window.innerWidth;
    isLarger.value = windowWidth.value > threshold;
  };

  onMounted(() => {
    if (typeof window !== "undefined") {
      windowWidth.value = window.innerWidth;
      window.addEventListener("resize", handleResize);
      handleResize();
    }
  });

  onUnmounted(() => {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", handleResize);
    }
  });

  return { isLarger };
}

export function useResponsiveBreakpoints() {
  const breakpoints = {
    mobile: 768,
    "tablet-portrait": 1024,
    "tablet-landscape": 1280,
    desktop: Infinity,
  };

  const isMobile = ref(false);
  const isTabletPortrait = ref(false);
  const isTabletLandscape = ref(false);
  const isDesktop = ref(true);

  const updateFlags = width => {
    isMobile.value = width < breakpoints.mobile;
    isTabletPortrait.value =
      width >= breakpoints.mobile && width < breakpoints["tablet-portrait"];
    isTabletLandscape.value =
      width >= breakpoints["tablet-portrait"] &&
      width < breakpoints["tablet-landscape"];
    isDesktop.value = width >= breakpoints["tablet-landscape"];
  };

  if (import.meta.client) {
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      updateFlags(windowWidth.value);
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });
  }

  return { isMobile, isTabletPortrait, isTabletLandscape, isDesktop };
}

// Este composable sirve para crear media querys sobre contenedores (elementos html) para utilizar desde js
export function useContainerSize(containerRef, threshold, callback) {
  if (window) {
    const containerWidth = ref(window.innerWidth);

    function handleResize(event) {
      containerWidth.value = event[0].contentRect.width;
      const isLarger = containerWidth.value > threshold;
      callback(isLarger);
    }

    let containerObserver;

    onMounted(async () => {
      await nextTick(); // Espera hasta que Vue actualice el DOM
      containerObserver = new ResizeObserver(handleResize);
      const element = containerRef.value?.$el
        ? containerRef.value.$el
        : containerRef.value;
      if (element) {
        containerObserver.observe(element);
      }
    });

    onBeforeUnmount(() => {
      const element = containerRef.value?.$el
        ? containerRef.value.$el
        : containerRef.value;
      containerObserver?.disconnect(element);
    });
  }
}

export function useByChildrenContainerSize(childrenRef, threshold) {
  const isLarger = ref(false);

  function updateSize(width) {
    isLarger.value = width > threshold;
  }

  let containerObserver;

  // Inicializamos el observer
  onMounted(() => {
    if (!childrenRef.value && !childrenRef.value?.parentElement) {
      console.error("useContainerSize: childrenRef no está definido");
      return;
    }
    const container = childrenRef.value.parentElement;
    const handleResize = entries => {
      const entry = entries[0];
      updateSize(entry.contentRect.width);
    };

    containerObserver = new ResizeObserver(handleResize);
    containerObserver.observe(container);
  });

  // Limpiamos el observer
  onBeforeUnmount(() => {
    const container = childrenRef.value?.parentElement;

    if (containerObserver && container) {
      containerObserver.unobserve(container);
    }
  });

  // Revisa y actualiza el tamaño inicialmente
  watch(
    childrenRef,
    (newValue, oldValue) => {
      if (newValue !== oldValue && newValue) {
        updateSize(newValue.clientWidth);
      }
    },
    { immediate: true }
  );

  return { isLarger };
}
